(function ($) {

  var $document = $(document);

    $document.ready(function () {
    //initialize swiper when document ready

    var swiper = new Swiper('.swiper-1', {
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
      pagination: {
        el: '.pagination-1',
        type: 'bullets',
        clickable: true,
      }
    });

    var swiper = new Swiper('.swiper-2', {
      pagination: {
        el: '.pagination-2',
        type: 'bullets',
        clickable: true,
      }
    });

  });
})(window.jQuery);

(function ($) {

  var $document = $(document);
  var $body = $(document.body);

  // Wrap everything in a DOM ready handler.
  $document.ready(function () {

    // Save the navbar collapse selectors so making updates/tracking easier.
    var navbarCollapseFirst = '#navbar-collapse-1';
    var navbarCollapseSecond = '#navbar-collapse-2';
    var navbarCollapseBoth = navbarCollapseFirst + ',' + navbarCollapseSecond;

    // Save the jQuery instances (for performance).
    var $navbarCollapseFirst = $(navbarCollapseFirst);
    var $navbarCollapseSecond = $(navbarCollapseSecond);

    // Variable for saving which navbar collapse is currently open.
    var $open = $();

    // For performance reasons, bind evens directly on the document. jQuery
    // allows you to pass a targeting selector between the event and handler
    // so it will only call said handler when the event matches said selector.
    $document
      // Bind "show" event for first navbar collapse.
      .on('show.bs.collapse', navbarCollapseBoth, function (e) {
        // Indicate that the first is open.
        $open = $(e.target);

        // Collapse the first if it's not the one that just opened.
        if (!$navbarCollapseFirst.is($open)) {
          $navbarCollapseFirst.collapse('hide');
        }
        // Collapse the second if it's not the one that just opened.
        else if (!$navbarCollapseSecond.is($open)) {
          $navbarCollapseSecond.collapse('hide');
        }

        // Add the body class.
        $body.addClass('overlay-is-navbar-collapse');
      })
      // Bind "hide" event for first navbar collapse.
      .on('hide.bs.collapse', navbarCollapseFirst, function (e) {
        // Indicate that the first is open.
        var $hide = $(e.target);

        // Remove the first as the opened navbar collapse.
        if ($navbarCollapseFirst.is($hide) && $navbarCollapseFirst.is($open)) {
          $open = $();
        }
        // Remove the second as the opened navbar collapse.
        else if ($navbarCollapseSecond.is($hide) && $navbarCollapseSecond.is($open)) {
          $open = $();
        }

        // Remove the body class if there is no open navbar collapse.
        if (!$open[0]) {
          $body.removeClass('overlay-is-navbar-collapse');
        }
      });
  });

})(window.jQuery);

(function ($) {
 $( document ).ready( scrolled );
 $( window ).scroll( scrolled );
 function scrolled() {
   $('.showonscroll').each( function() {
     if ( !$(this).hasClass( "show" ) ) {
       if ( $(window).scrollTop() >= ( $(this).offset().top - $(window).height() + 80) ) {
         $(this).addClass( "show" );
       }
     }
   } );
 }
})(window.jQuery);
