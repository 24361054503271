var js = {};
! function(e) {
    e.fn.exists = function() {
        return e(this).length > 0
    }, js.model = {
        events: {},
        extend: function(t) {
            var s = e.extend({}, this, t);
            return e.each(s.events, function(e, t) {
                s._add_event(e, t)
            }), s
        },
        _add_event: function(t, s) {
            var i = this,
                o = t,
                n = "",
                a = document;
            t.indexOf(" ") > 0 && (o = t.substr(0, t.indexOf(" ")), n = t.substr(t.indexOf(" ") + 1)), "resize" != o && "scroll" != o && "load" != o || (a = window), e(a).on(o, n, function(t) {
                t.$el = e(this), "function" == typeof i.event && (t = i.event(t)), i[s].apply(i, [t])
            })
        }
    }, js.header = js.model.extend({
        $header: null,
        $sub_header: null,
        active: 0,
        hover: 0,
        show: 0,
        y: 0,
        opacity: 1,
        direction: "down",
        events: {
            ready: "ready",
            scroll: "scroll",
            "mouseenter #header": "mouseenter",
            "mouseleave #header": "mouseleave"
        },
        ready: function() {
            this.$header = e("#header"), this.$sub_header = e("#hero"), this.active = 1
        },
        mouseenter: function() {
            var t = e(window).scrollTop();
            this.hover = 1, this.opacity = 1, this.show = t, this.$header.stop().animate({
                opacity: 1
            }, 250)
        },
        mouseleave: function() {
            this.hover = 0
        },
        scroll: function() {
            if (this.active) {
                var t = e(window).scrollTop(),
                    s = t >= this.y ? "down" : "up",
                    i = s !== this.direction,
                    o = t - this.y,
                    n = this.$sub_header.outerHeight();
                clearTimeout(this.t), t < 70 && this.$header.removeClass("-white"), i && (0 == this.opacity && "up" == s ? (this.show = t, t < n ? this.show = 0 : this.show -= 70) : 1 == this.opacity && "down" == s && (this.show = t), this.show = Math.max(0, this.show)), this.$header.hasClass("-open") && (this.show = t), this.hover && (this.show = t);
                var a = t - this.show;
                a = Math.max(0, a);
                var l = (70 - (a = Math.min(a, 70))) / 70;
                this.$header.css("opacity", l), t > n ? this.$header.addClass("-white") : 0 == l && this.$header.removeClass("-white"), this.y = t, this.direction = s, this.opacity = l
            }
        }
    })
}(jQuery)